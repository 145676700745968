import React, {useState} from 'react'
import styles from './App.module.less'
import assets from './assets'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

const isQb = window.location.host.includes('9.17')
const isQa = window.location.host.includes('9.16')
const isProd = /c(om|n)/.test(window.location.host)

function App() {

    const MAP = {
        ABOUT: 'about',
        BEAUTY: 'beauty',
        COMMUNITY: 'community',
        MALL: 'mall',
        DOWN: 'down',
    }

    function goEle(id) {
        const ele = document.getElementById(id)
        scrollIntoView(ele, { behavior: 'smooth' })
    }

    const origin = isQa ? 'http://10.100.9.16:8088' : isQb ? 'http://10.100.9.17:8088' : 'https://meilly-account-admin.meilly.cn'

    return (
        <div className={styles.app}>
            <header className={styles.header}>
                <div className={styles.header_content}>
                    <div className={styles.logo}><img src={assets.logo}/></div>
                    <div className={styles.header_right}>
                        <div onClick={_ => goEle(MAP.ABOUT)} className={styles.header_item}>关于美栗</div>
                        <div onClick={_ => goEle(MAP.BEAUTY)} className={styles.header_item}>美研院</div>
                        <div onClick={_ => goEle(MAP.COMMUNITY)} className={styles.header_item}>美栗社区</div>
                        <div onClick={_ => goEle(MAP.MALL)} className={styles.header_item}>美栗商城</div>
                        <div onClick={_ => goEle(MAP.DOWN)} className={styles.header_item}>下载APP</div>
                        {/* <div className={styles.header_item}><a href={origin + '/apply/hospital?from=official'}
                                                               target={'_blank'}>医院入驻</a>
                        </div>
                        <div className={styles.header_item}><a href={origin + '/user/login'}
                                                               target={'_blank'}>登录</a>
                        </div> */}
                    </div>
                </div>
            </header>
            <div className={styles.banner}>
                <div className={styles.banner_bg}><img src={assets.banner}/></div>
                <div className={styles.slogan}><img src={assets.slogan}/></div>
                <div className={styles.hello}>Hey! Melly</div>
            </div>
            <div id={MAP.ABOUT} className={styles.about}>
                <div className={styles.about_bg}><img src={assets.about_bg}/></div>
                <div className={styles.about_content}>
                    <div className={styles.about_main}>About Melly</div>
                    <div className={styles.about_chs}>关于我们</div>
                    <div
                        className={styles.about_text}>美栗是“美”的聚集地。我们建立一个平台，汇集关于“美”的内容、产品和服务，让人们分享、发现和消费，让生活和自己一起变得更加美好。
                    </div>
                </div>
            </div>
            <div id={MAP.BEAUTY} className={styles.beauty}>
                <div className={styles.beauty_bg}><img src={assets.beauty}/></div>
                <div className={styles.beauty_content}>
                    <div className={styles.beauty_title}>Beauty Institute Melly</div>
                    <div className={styles.beauty_chs}>
                        <div className={styles.beauty_name}>
                            美研院
                        </div>
                        <div className={styles.beauty_text}>重度爱美用户的信息来源</div>
                        <div className={styles.beauty_text_for}>围绕“美”的核心价值，</div>
                        <div className={styles.beauty_text_will}>美研院将推出专业有趣的权威内容、主题活动、话题讨论，</div>
                        <div className={styles.beauty_text_will}>覆盖与美相关的各个垂直领域。</div>
                    </div>
                </div>
            </div>
            <div id={MAP.COMMUNITY} className={styles.community}>
                <div className={styles.community_person}><img src={assets.community_person}/></div>
                <div className={styles.community_content}>
                    <div className={styles.community_top}>
                        <div className={styles.community_title}>Melly’s</div>
                        <div className={styles.community_title}>Community</div>
                    </div>
                    <div className={styles.community_bottom}>
                        <div>美栗社区</div>
                        <div>热衷美，分享美，让美发生</div>
                        <div>美是现在进行，美是当下拥有，美是正在发生。</div>
                        <div>在这里，记录和分享美栗生活、变美秘籍；</div>
                        <div>在这里，看见美好的例子。</div>
                    </div>
                </div>
                <div className={styles.wall}>
                    <div className={styles.community_img}>
                        <img src={assets.community1}/>
                        <div className={styles.community_img_bottom}>
                            <div className={styles.community_img_text}>探店只要几百块就能...</div>
                            <div className={styles.community_img_right}><img src={assets.fav}/>398</div>
                        </div>
                    </div>
                    <div className={styles.community_img}>
                        <img src={assets.community2}/>
                        <div className={styles.community_img_bottom}>
                            <div className={styles.community_img_text}>丸子头教程</div>
                            <div className={styles.community_img_right}><img src={assets.fav}/>398</div>
                        </div>
                    </div>
                    <div className={styles.community_img}>
                        <img src={assets.community3}/>
                        <div className={styles.community_img_bottom}>
                            <div className={styles.community_img_text}>办公室懒人操</div>
                            <div className={styles.community_img_right}><img src={assets.fav}/>398</div>
                        </div>
                    </div>
                    <div className={styles.community_img}>
                        <img src={assets.community4}/>
                        <div className={styles.community_img_bottom}>
                            <div className={styles.community_img_text}>美妆 优雅</div>
                            <div className={styles.community_img_right}><img src={assets.fav}/>398</div>
                        </div>
                    </div>
                </div>
            </div>
            <div id={MAP.MALL} className={styles.mall}>
                <div className={styles.mall_bg}><img src={assets.mall_bg}/></div>
                <div className={styles.mall_content}>
                    <div className={styles.mall_title}>Melly Mall</div>
                    <div className={styles.mall_bottom}>
                        <div>美栗商城</div>
                        <div>口碑爆款真品，一站美丽获得</div>
                        <div>稀缺的大牌爆款、 前沿的潮流尚品、达人的独家秘方、可靠的口碑推荐……</div>
                        <div> 来到美栗商城，总能找到适合自己的那一款。</div>
                    </div>
                </div>
            </div>
            <div id={MAP.DOWN} className={styles.footer}>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div>2019</div>
                        <div>See you soon!</div>
                        <div>你的美栗即将上线</div>
                        {/*<div><img src={assets.contact}/></div>*/}
                        <div className={styles.left_bottom}>
                            <div>联系我们：</div>
                            <div><span>E:</span> hezuo@meilly.cn</div>
                            <div>浙江省 杭州市 下城区绍兴路398号 国投大厦 10层</div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.download}>
                            <img src={assets.download}/>
                            <div>立即扫码下载美栗APP</div>
                        </div>
                        <img src={assets.phone}/>
                    </div>
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.bottom_content}>
                    <div>              
                        <div>
                            <a href={assets.yaopin} target={'_blank'} className={styles.icplink}>{'互联网药品信息服务资格证编号(浙)-非经营性-2019-0094'}</a>
                            {' | '}
                            <a href={'https://beian.miit.gov.cn/#/Integrated/index'} target={'_blank'} className={styles.icplink}>{'浙ICP备19015381号-1'}</a>
                        </div>                                                
                        <div><a href={'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010302003354'} target={'_blank'} className={styles.safe}><img src={assets.safe}/>浙公网安备 33010302003354</a></div>                                                        
                        <div>Copyright 2021 Hangzhou Yingying Information Science Technology Co., Ltd. All Rights Reserved.</div>    
                        <div>版权所有：杭州盈盈信息科技有限公司</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
